<template>
  <div ref="myChart" style="width: 100%;height:300px;"></div>
</template>

<script>
import * as echarts from 'echarts'
import { getDegree } from '@/api/http'
export default {
  mounted() {
    this.geTList()
  },
   watch: {  
      '$route.query'(newQuery) {  
        // 当查询参数变化时，重新调用API  
        console.log(newQuery);
		this.geTList()
      }  
    }, 
  methods: {
    async geTList() {
      const getCatData = await getDegree({ id: this.$route.query.id })
      let xAxisData = []
      let seriesData = []
      // console.log('------ getCatData.data.data------', JSON.stringify(getCatData.data.data))
      getCatData.data.data.forEach((item) => {
        xAxisData.push(this.formatDate(item.createtime))
        seriesData.push([this.formatDate(item.createtime), item.degree])
      })

      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.myChart)

      // 指定图表的配置项和数据
      const option = {
        grid: {
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            // smooth: true, // 设置为true以使折线平滑
            symbolSize: 12,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0.2,
                      color: 'rgba(172,191,251, 0.7)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(172,191,251, 0.2)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            }
            // itemStyle: {
            //   normal: {
            //     color: '#0092f6',
            //     lineStyle: {
            //       color: '#0092f6',
            //       width: 3
            //     },
            //     areaStyle: {
            //       //color: '#94C9EC'
            //       color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            //         {
            //           offset: 0.4,
            //           color: 'rgba(60, 70, 255, 0)'
            //         },
            //         {
            //           offset: 1,
            //           color: 'rgba(25, 125, 240, 1)'
            //         }
            //       ])
            //     }
            //   }
            // }
          }
        ]
      }
      // 自定义工具提示的样式
      option.tooltip = {
        formatter: function(params) {
          // 返回一个包含两行的字符串，第一行是时间，第二行是流量值
          return `<div style="background-color: #fff;">
                  <p style="font-size: 14px; font-weight: bold;">${params.name}</p>
                  <p style="font-size: 12px;color:#2254f4">流量： ${params.value[1]}</p>
                </div>`
        }
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
    formatDate(timestamp) {
      const date = new Date(Number(timestamp) * 1000)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }
}
</script>
