<template>
	<div>
		<!-- 锚点导航 -->
		<asideBar :navMenuList="navMenuList" />
		<div class="sidebar_right mini-sidebar">
			<div class="main-content flex-fill">
				<div class="container customize-width px-md-5 mt-4"
					:style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }">
					<nav class="text-sm text-muted mb-3 mb-md-4">
						<i class="iconfont icon-home mr-2"></i><a class="crumbs" href="index.html">首页</a><i
							class="px-2">/</i><span>{{ detail.title }}</span>
					</nav>
					<div class="content-body">
						<div class="content-wrap">
							<div class="content-layout">
								<div class="site-content mb-3 mb-sm-5">
									<div class="row">
										<div class="col-12 col-sm-5 col-md-4 col-lg-3">
											<div class="siteico">
												<img class="img-cover lazy" :src="detail.image" height="auto"
													:alt="detail.title" />
											</div>
										</div>
										<div class="col mt-2 mt-sm-0">
											<div class="site-body text-sm">
												<div class="d-flex align-items-center copy-item mt-3">
													<h2 class="site-name h3 copy-text mb-0">{{ detail.title }}</h2>
													<i class="io io-renzheng icon-fw icon-lg ml-1" data-toggle="tooltip"
														data-placement="bottom" data-original-title="已加入启动托管"></i><a
														href="javascript:" class="text-sm text-primary copy-btn ml-2"><i
															class="iconfont icon-instructions mr-1"></i>复制</a>
												</div>
												<p class="my-2">
													<el-tooltip class="item" effect="dark" content="此网站具有由受信任的机构颁发的有效证书"
														placement="bottom">
														<span class="text-lg mr-2" data-toggle="tooltip"
															data-placement="bottom"
															data-original-title="此网站具有由受信任的机构颁发的有效证书">🔏</span>
													</el-tooltip>

													<el-tooltip class="item" effect="dark" content="此网站存在付费服务"
														placement="bottom">
														<span class="text-lg mr-2" data-toggle="tooltip"
															data-placement="bottom"
															data-original-title="此网站存在付费服务">💰</span>
													</el-tooltip>
													<el-tooltip class="item" effect="dark" content="此网站存在广告"
														placement="bottom">
														<span class="text-lg mr-2" data-toggle="tooltip"
															data-placement="bottom"
															data-original-title="此网站存在广告">🎴</span>
													</el-tooltip>
												</p>
												<p class="text-sm text-muted mt-2 mb-0">更新时间：{{ detail.uptime }}</p>
												<p class="mt-3 mb-2">
													{{ detail.content }}
												</p>
												<div class="site-go mt-4">
													<a class="btn btn-arrow active mr-2" :title="detail.title"
														rel="nofollow" @click="clickJump(detail.link)"><span>启动网站<i
																class="iconfont icon-arrow-r-m"></i></span></a><a
														class="btn" data-toggle="dropdown"
														v-clipboard:copy="'https://' + detail.link"
														v-clipboard:success="successClick"><i
															class="iconfont icon-url mr-2"></i>复制链接</a>
													<!-- <div class="dropdown-menu text-sm">
                            <a class="dropdown-item copy-location-href" href="javascript:"><i class="iconfont icon-url mr-2"></i>复制链接</a>
                          </div> -->
												</div>
												<p class="tags my-3">
													<a href="#" class="mr-2" v-for="item in detail.json_data">
														{{ item.title }}
													</a>
													<!-- <a href="/category/21"
														class="mr-2">高清图库</a><a href="/category/30"
														class="mr-2">Cosplay</a><a href="77.html"
														class="mr-2">花猫联盟</a><a href="/category/89"
														class="mr-2">壁纸图片</a><a href="/s//?q=花猫壁纸&post_type=sites"><i
															class="iconfont icon-search"></i>花猫壁纸</a> -->
												</p>
											</div>
										</div>
									</div>
								</div>
								<main>
									<div id="section1" class="category-swiper">
										<div class="swiper-wrapper nav flex-nowrap">
											<a style="width: auto" id="base-tab"
												class="btn btn-search text-gray swiper-slide mr-2 active"
												data-toggle="tab" href="#base" role="tab" aria-controls="base"
												aria-selected="true">基础信息</a>
										</div>
									</div>
									<div class="tab-content">
										<div class="tab-pane fade show active" id="base" role="tabpanel"
											aria-labelledby="base-tab">
											<div class="panel site-content card mt-3 mb-5">
												<div class="card-body">
													<div class="panel-body pt-2">
														<div class="row">
															<div class="col-lg-6">
																<p class="mb-2 copy-item">
																	<span class="mr-2">官网：<a href="index1.html"
																			target="_blank" rel="nofollow"><i
																				class="iconfont icon-globe mr-1"></i><span
																				class="copy-text">{{ detail.link }}</span></a></span><a
																		href="javascript:" class="text-sm copy-btn"><i
																			class="iconfont icon-instructions mr-1"></i>复制</a>
																</p>
															</div>
															<div class="col-lg-6">
																<p class="mb-2 copy-item">
																	<span class="mr-2">成立日期：<span
																			class="mr-1">📅</span><span
																			class="copy-text">{{ detail.cj_time }}</span></span><a
																		href="javascript:" class="text-sm copy-btn"><i
																			class="iconfont icon-instructions mr-1"></i>复制</a>
																</p>
															</div>
															<div class="col-lg-6">
																<p class="mb-2 copy-item">
																	<span class="mr-2">邮箱：<a
																			href="mailto:heisezhizhu@protonmail.com"
																			target="_blank" rel="nofollow"><i
																				class="iconfont icon-gonggao4 mr-1"></i><span
																				class="copy-text">{{ detail.email }}</span></a></span><a
																		href="javascript:" class="text-sm copy-btn"><i
																			class="iconfont icon-instructions mr-1"></i>复制</a>
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="block-header my-3 my-md-4" v-if="xjList.length > 0">
										<h4 class="block-title"><!-- <i class="io io-gonggao"
												id="l-5"></i> -->{{ detail.title }}&nbsp;替代品</h4>
										<div class="flex-fill"></div>
										<!-- <a href="/website-alternative/1">查看<i class="iconfont icon-arrow-r-m"></i></a> -->
									</div>
									<div class="card-grid" v-if="xjList.length > 0">
										<div class="url-card" v-for="item in xjList" :key="item.id"
											v-if="deviceType !== 'phone'">
											<div class="url-body">
												<a @click="goDetails(item.id)" class="url-content">
													<div class="url-img">
														<img class="lazy" :src="item.image" />
													</div>
													<div class="url-info">
														<div class="overflowClip_1 mb-1 mb-md-2">
															<strong>{{ item.title }}</strong><span
																class="badge">完全免费</span>
														</div>
														<p class="overflowClip_2">
															{{ item.content }}
														</p>
													</div>
												</a>
												<div class="url-link">
													<div class="swiper-wrapper">
														<a class="swiper-slide" target="_blank" rel="nofollow"
															v-for="item1 in item.tag">{{ item1 }}</a>
													</div>
												</div>
												<a href="index4.html" class="url-details" target="_blank"
													rel="nofollow"><i class="iconfont icon-goto"></i></a>
											</div>
										</div>


										<div class="gengduo" v-if="deviceType == 'phone'">
											<div class="list">
												<div class="item" v-for="items in xjList" @click="goDetails(items.id)">
													<div class="left">
														<img class="lazy" :src="items.image" :alt="items.title" />
														<p style="margin-left: 10px;"><strong>{{ items.title }}</strong>
														</p>
													</div>
													<div class="right">

													</div>
												</div>
											</div>
										</div>
									</div>

									<div id="section2" class="block-header my-3 my-md-4">
										<h4 class="block-title"><!-- <i class="io io-xindong"
												id="l-6"></i> -->{{ detail.title }}&nbsp;流量情况</h4>
										<div class="flex-fill"></div>
										<!-- <a href="/website-traffic/1">详细<i class="iconfont icon-arro1w-r-m"></i></a> -->
									</div>
									<div class="pb-3 pb-md-4">
										<svg height="0" width="0">
											<defs>
												<lineargradient id="morris-area-gradient" x1="0%" y1="0%" x2="0%"
													y2="100%">
													<stop offset="0%"
														style="stop-color: var(--theme-color); stop-opacity: .4"></stop>
													<stop offset="100%"
														style="stop-color: var(--theme-color); stop-opacity: 0"></stop>
												</lineargradient>
											</defs>
										</svg>
										<div id="trend" class="morris-area" style="height: 300px">
											<echartslist :id="id"></echartslist>
										</div>
									</div>
									<div class="block-header my-3 my-md-4">
										<h4 class="block-title">
											<!-- <i class="io io-renwu" id="l-2"></i> -->
										相关教程</h4>
									</div>
									<div class="card-grid">
										<div class="post-card" v-for="item in jcList">
											<div class="post-body">
												<div class="post-content">
													<a href="/post/37"
														class="overflowClip_2"><strong>{{ item.title }}</strong></a>
													<div class="post-footer">
														<div class="post-time">{{ item.time }}</div>
														<div class="flex-fill"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id="section3" class="block-header my-3 my-md-4">
										<h4 class="block-title"><!-- <i class="io io-tonggao" id="l-3"> --></i>相关科普</h4>
										<div class="flex-fill"></div>
										<!-- <a href="/popsci/1">更多<i class="iconfont icon-arrow-r-m"></i></a> -->
									</div>
									<div class="card-grid">
										<div class="post-card" v-for="item in kpList">
											<div class="post-body">
												<div class="post-content">
													<a href="/post/125"
														class="overflowClip_2"><strong>{{ item.title }}</strong></a>
													<div class="post-footer">
														<div class="post-time">{{ item.time }}</div>
														<div class="flex-fill"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id="section4" class="block-header my-3 my-md-4">
										<h4 class="block-title"><!-- <i class="io io-yinle" id="l-4"></i> -->相关资讯</h4>
										<div class="flex-fill"></div>
										<!-- <a href="/news/1">更多<i class="iconfont icon-arrow-r-m"></i></a> -->
									</div>
									<div class="card-grid">
										<div class="post-card" v-for="item in zxList">
											<div class="post-body">
												<div class="post-content">
													<a href="/post/106"
														class="overflowClip_2"><strong>{{ item.title }}</strong></a>
													<div class="post-footer">
														<div class="post-time">{{ item.time }}</div>
														<div class="flex-fill"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</main>
							</div>
						</div>
						<div class="sidebar sidebar-tools d-none d-lg-block">
							<div class="io-sidebar-widget io-widget-post-list mb-4">
								<div class="hot-card">
									<div class="card-header widget-header">
										<h3 class="text-md mb-0">热门链接</h3>
									</div>
									<div class="card-body">
										<ul>
											<li class="d-flex text-sm" v-for="(item, index) in getheadList">
												<div>
													<span class="hot-rank hot-rank-1">{{ index + 1 }}</span>
													<a @click="clickJump2(item.link)" rel="nofollow">{{ item.name }}</a>
												</div>
											</li>

										</ul>
									</div>
								</div>
							</div>
							<div class="card-header widget-header mb-3">
								<h3 class="text-md mb-0">白金赞助</h3>
							</div>
							<div class="card-grid">
								<div v-for="item in guanggaoList" class="sponsors-card"
									@click="clickJump2(item.content)">
									<div class="sponsors-body">
										<img class="hmads" style="width: 100%;height: 130px;" :src="item.image"
											alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 弹框 -->
		<asideDrawer :navMenuList="navMenuList" />
	</div>
</template>

<script>
	import asideBar from '@/views/layout/components/aside'
	import asideDrawer from '@/views/layout/components/asideDrawer'
	import echartslist from './echartslist.vue'
	import {
		API_URL
	} from '@/utils/request.js'
	import {
		getDetails,
		getNav,
		guanggao,
		gethead,
		getNews,
		setBrowse
	} from '@/api/http'
	export default {
		components: {
			echartslist,
			asideBar,
			asideDrawer
		},

		data() {
			return {
				id: '',
				cid: '',
				detail: {},
				xjList: [],
				guanggaoList: [],
				getheadList: [],
				navMenuList: [{
						id: '1',
						name: '相关信息',
						icon: 'io io-faxian'
					},
					{
						id: '2',
						name: '流量情况',
						icon: 'io io-xindong'
					},
					{
						id: '3',
						name: '相关科普',
						icon: 'io io-tonggao'
					},
					{
						id: '4',
						name: '相关资讯',
						icon: 'io io-yinle'
					}
				],
				kpList: [],
				zxList: [],
				jcList: [],
				deviceType: ''
			}
		},

		created() {
			this.id = this.$route.query.id
			this.cid = this.$route.query.cid
			this.getDetails()
			this.getXiangJin()
			this.getBaijin()
			this.gethead()
			this.getNew()
		},
		methods: {
			async goDetails(val) {
				var query = {
					id: val
				}
				const setBrowses = await setBrowse(query)
				this.$router.replace({
					path: '/details',
					query: {
						id: val,
						cid: this.cid
					}
				})
				this.id = val;
				this.xjList = [];
				this.kpList = []
				this.jcList = []
				this.zxList = []
				this.getDetails()
				this.getXiangJin()
				this.getBaijin()
				this.gethead()
				this.getNew()
				// this.id = val;
				// this.xjList = [];
				// this.getNew();
				// this.getDetails();
				// this.getXiangJin();
			},
			//资讯
			async getNew() {
				var query = {
					navigation_id: this.id
				}
				const data = await getNews(query)
				var datas = data.data.data.rows
				this.kpList = datas.status1.data
				this.jcList = datas.status2.data
				this.zxList = datas.status3.data

				console.log(this.kpList)
			},
			//获取相近的搜索
			async getXiangJin() {
				var cid = {
					category_id: this.cid
				}
				const xiangjin = await getNav(cid)
				var data = xiangjin.data.data
				data.forEach((item) => {
					if (item.id == this.cid) {
						item.nav.forEach((item1) => {
							if (this.id != item1.id) {
								item1.image = API_URL + item1.image
								item1.tag = item1.tag.split(/\s*,\s*/)
								console.log(item1)
								this.xjList.push(item1)
							}
						})
					}
				})
			},
			async getBaijin() {
				var data1 = {
					type: 0
				}
				const baijin = await guanggao(data1)
				var guanggap = baijin.data.data
				guanggap.forEach((item) => {
					item.image = API_URL + item.image
				})
				this.guanggaoList = guanggap
			},
			async gethead() {
				var query = {
					type: 2
				}
				const data = await gethead(query)
				this.getheadList = data.data.data.reverse()
			},
			//获取详情
			async getDetails() {
				var query = {
					id: this.id
				}
				const getDetais = await getDetails(query)
				var data = getDetais.data.data
				data.image = API_URL + data.image
				this.detail = data
				if (this.detail.json_data) {
					this.detail.json_data = JSON.parse(this.detail.json_data)
				}
				var times = 'times'
				var strDate = ''
				var date = new Date(this.detail.updatetime * 1000)
				var Y = date.getFullYear() + '-'
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
				var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
				var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()

				strDate = Y + M + D + h + m
				this.detail.uptime = strDate
			},
			clickJump(val) {
				window.open('https://' + val, '_blank')
			},
			// 复制成功
			successClick() {
				this.$message({
					message: '复制成功',
					type: 'success'
				})
			},
			clickJump2(val) {
				window.open(val, '_blank')
			}
		}
	}
</script>

<style>
	@import url('../css/bootstrap.css');
	@import url('../css/style.css');

	.gengduo .list img {
		width: 30px;
		height: 30px;
	}

	.item .left {
		display: flex;
		align-items: center;
	}

	.item .left p {
		width: 120px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px 0;
	}

	.item {
		width: 48%;
		background: #F1F4F9;
		margin-top: 10px;
		border-radius: 10px;
		padding: 10px;
		justify-content: space-between;
	}
</style>